@import '../Resume.module.css';

.Borderless {
    border: 0 none !important;
}

.Skill {
    display: flex;
    justify-content: space-between;
    border: 0 none;
}

.Skill:hover {
    cursor: pointer;
}

.Skill:hover .Name {
    transform: scale(1.1);
    transition: transform 0.05s linear;
}

.Skill:hover .StarList {
    transform: scale(1.1);
    transition: transform 0.05s linear;
}

.Skill .StarList .Starfalse {
    opacity: 0.4
}

.Skill .StarList .Star {
    height: 1rem;
    width: 1rem;
}