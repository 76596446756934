
.Tag {
    font-size: 1rem; 
    font-weight:500; 

    display: flex;
    justify-content: center;
    align-items: center;
}

.DownloadDiv {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
}

.Download {
    display: block;
    padding: 0.25rem;
    box-shadow: 0.1rem;
    box-sizing: border-box;
    border-style: groove;
    border-radius: 0.025rem;
    font-size: 1rem; 
}

.Download:hover {
    cursor: pointer !important;
    text-decoration: none;
    transform: scale(1.1, 1.1);
    transition: transform 0.3s;
}