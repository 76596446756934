@import '../Resume.module.css';

.Project {
    text-align: left;
    font-size: 0.8rem;
}

.Project .ProjectImg {
    height: 9rem;
    border-style: solid;
    border-color: var(--resume-second-dominant);
    border-bottom: none;
}

.Project:hover .Overlay {
    color: var(--resume-second-dominant);
    background: rgba(243, 53, 53, 0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: auto 10%;
    text-align: left;
    cursor: pointer;
}

.Project:hover .Overlay h4 {
    margin: 0 0 0.1rem 0;
    text-align: left;
}

.Project .Overlay .Line {
    left: -100%;
    margin: 0.1rem auto;
}

.Project .Overlay:hover .Line {
    width: 100%;
    border: 1px solid var(--resume-second-dominant);
    border-radius: 1px;
    position: relative;
    left: 0%;
    transition: left 0.2s ease-in-out;
}

.Project .Overlay .Tags {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    left: 100%;
}

.Project .Overlay:hover .Tags {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    left: 0%;
    transition: left 0.3s ease-in-out;
}

.Project .Tag {
    margin-right: 1%;
    font-style: italic;
    text-decoration: underline;
    font-weight: bold;
}

.Project .Overlay {
    display: none
}

.Project .Overlay .Description {
    position: relative;
    left: 100%;
}

.Project .Overlay:hover .Description {
    position: relative;
    left: 0%;
    transition: left 0.2s ease-in-out;
}

.Project .Body {
    background: rgba(243, 53, 53, 0.8);
    color: var(--resume-second-dominant);
}

.Project .Body .Tags {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
}

@media (max-width: 1200px) {
    .Project .ProjectImg {
        max-height: 15rem;
    }
}

@media (max-width: 767px) {
    .Project {
        margin-bottom: 2rem;
    }

    .Project .ProjectImg {
        height: 30rem;
        border-style: none;
    }

    .Project .Tags {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        position: relative;
    }
}