.ProfilePage {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.ProfilePage p {
    line-height: 1.5em;
}

.ProfilePage h3 {
    font-size: 1.4rem; 
    margin-bottom: 10%; 
    font-weight: 100;
}

@media (max-width: 767px) {
    .ProfilePage {
        justify-content: center;
        align-items: center;
    }

    .ProfilePage h3 {
        margin-bottom: 0;
        text-align: center;
        font-size: 2.8rem;
    }

    .ProfilePage p {
        text-align: center;
    }
}