:root {
    font-size: 1vw;
}

.App {
    font-size: 1rem;
}

.App h4 {
    font-size: 1rem;
}

@media (max-width: 767px) {
    :root {
        font-size: 2vw;
    }

    .App {
        font-size: 2rem;
    }

    .App h1 {
        font-size: 3rem;
    }
    
    .App h4 {
        font-size: 2rem;
    }
}