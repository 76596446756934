.Blog {
    position: absolute !important;
    top: 0.6rem;
    right: 0rem;
    z-index: 100;
    transition: ease-in right 0.1s;
}

.Blog:hover {
    right: 0.3rem;
}