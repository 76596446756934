@import '../Resume.module.css';

.SkillPage .Borderless {
    border: 0 none;
    background-color: var(--resume-third-dominant);
}

.SkillType {
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 1.25rem; /* comes from .list-group-item */
}