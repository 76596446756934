@import '../Resume.module.css';

.Navigation {
    position: fixed;
    top:20%; 
    right:3%;
    z-index: 100;
}

.Navigation0 {
    position: absolute;
    top: calc(100vh - 6vh); /* -6 is from below height of Active and Inactive 6vh */
    right: 3%;
    z-index: 100;
}

.Navigation .Inactive, .Navigation0 .Inactive, .Navigation .Active, .Navigation0 .Active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 6vh;
}

.Navigation .Inactive, .Navigation0 .Inactive {
    font-size: 1.8rem;
    font-weight: 300;
}

.Navigation .Active,.Inactive:hover {
    cursor: pointer;
    transition: transform 0.1s linear;
    transform: scale(1.2, 1.2);
}

.Navigation0 .Active:first-of-type {
    cursor: pointer;
}

.Navigation .Active, .Navigation0 .Active {
    font-size: 1.9rem;
}

.Navigation .Arrow {
    height: 100%;
    max-width: 8rem;
    animation: ArrowUp 0.5s linear alternate;
}

.Navigation0 .Arrow {
    height: 100%;
    max-width: 8rem;
    animation: 
        turnArrowDown 0.5s linear,
        ArrowDown 1s 0.5s linear alternate infinite;
}

@keyframes turnArrowDown {
    from   { transform: rotateX(0deg); }
    to { transform: rotateX(180deg); }
}

@keyframes ArrowDown {
    0%   { transform: rotateX(180deg) translateY(0); }
    50%  { transform: rotateX(180deg) translateY(-0.5rem); }
    100% { transform: rotateX(180deg) translateY(0); }
}

@keyframes ArrowUp {
    from { transform: rotateX(180deg); }
    to { transform: rotateX(0deg); }
}
