@import '../Resume.module.css';

.Work {
    margin-bottom: 3%;
}

.Work .Left {
    text-align: right;
}

.Work .Middle {
    text-align: center;
}

.Work .Icon {
    height: 1.9rem;
}

.Work .Line {
    border-left: 0.286rem solid var(--resume-second-dominant);
    height: calc(100% - 1.9rem);
    position: absolute;
    left: 50%;
    margin-left: -0.14rem;
    top: 2.38rem;
}

.Work .FirstLevelBullet {
    margin-bottom: 3%;
}

.Work .FirstLevelBullet .Toggle {
    cursor: pointer;
}

.Work .FirstLevelBullet .Toggle:hover {
    transform: scale(1.1, 1.1);
    transform-origin: top left;
}

.Work .FirstLevelBullet .Toggle .BulletPointerUntoggled {
    height: 1rem;
    margin-right: 1px;
    transform: rotate(90deg);
}

.Work .FirstLevelBullet .Toggle .BulletPointerToggled {
    height: 1rem;
    margin-right: 1px;
    transform: rotate(180deg);
    transition: transform 0.1s linear;
}

@media (max-width: 767px) {
    .Work {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 6%;
    }

    .Work .Left {
        text-align: left;
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .Work .FirstLine {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .Work .Icon, .Work .Timeline {
        margin-right: 1rem;
    }

    .Work .SecondLine {
        width: 100%;
    }
}