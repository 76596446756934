@import '../components/MyWork/Resume.module.css';

.OtherPage {
    margin-top: 4%;
    margin-bottom: 4%;
}

.OtherPage .OtherPageTitle {
    text-align:center;
    margin-bottom:3%;
    font-size: 2.6rem;
    font-weight: 500;
}

.OtherPage .Qoute {
    text-align:center;
    margin:2% auto 4%; 
    font-size: 1rem;
}

.OtherPage .Line {
    padding-bottom: 4%;
}

@media (max-width: 767px) {
    .OtherPage {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .OtherPage .OtherPageTitle {
        font-size: 4rem;
    }

    .OtherPage .Qoute {
        font-size: 2rem;
    }
}