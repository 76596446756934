.FirstPage {
    height: 100vh;

    display: flex;
    align-items: center;
}

.Image {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}